import 'mdn-polyfills/NodeList.prototype.forEach';
import IconPicker from './IconPicker';



document.addEventListener('DOMContentLoaded', () => {
  const iconPickers = document.querySelectorAll('.js-iconDownload');
  if (!iconPickers.length) {
    return;
  }

  iconPickers.forEach((element) => {
    const iconPicker = new IconPicker(element);
    iconPicker.mount(element);
  });
});
