import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Color {
  constructor(element) {
    this.element = element;

    this.button = this.element.querySelector(".c-color__button");
    this.buttonIcon = this.element.querySelector(".c-button__icon");
    this.buttonLabel = this.button.querySelector(".c-button__text__feedback");
    this.buttonCopied = this.button.querySelector(".c-button__text__copied");
    this.hexaValue = this.button.getAttribute('data-hexa');

    this.copy = this.copy.bind(this);
    this.svgColler = '<svg class="c-icon c-button__icon" width="16" height="16" aria-hidden="true"><path fill="currentColor" d="M15.533 4.718a.747.747 0 0 0-1.058 0l-7.717 7.717L3.54 9.217a.748.748 0 0 0-1.057 1.058l3.75 3.75a.747.747 0 0 0 1.057 0l8.243-8.242a.747.747 0 0 0 0-1.058v-.007Z"/></svg>';
    this.svgDefault = '<svg class="c-icon c-button__icon" width="16" height="16" aria-hidden="true"><path fill="currentColor" d="M14.25 5.25H7.5A2.25 2.25 0 0 0 5.25 7.5v6.75A2.26 2.26 0 0 0 7.5 16.5h6.75a2.26 2.26 0 0 0 2.25-2.25V7.5a2.25 2.25 0 0 0-2.25-2.25Zm.75 9a.76.76 0 0 1-.75.75H7.5a.76.76 0 0 1-.75-.75V7.5a.75.75 0 0 1 .75-.75h6.75a.75.75 0 0 1 .75.75v6.75Z"/><path fill="currentColor" d="M3 9.79h-.75a.81.81 0 0 1-.53-.22.67.67 0 0 1-.16-.25.71.71 0 0 1-.06-.28V2.29a.79.79 0 0 1 .22-.54.78.78 0 0 1 .24-.16.58.58 0 0 1 .29-.05H9a.78.78 0 0 1 .53.21.73.73 0 0 1 .22.54v.75a.75.75 0 0 0 1.5 0v-.75A2.25 2.25 0 0 0 9 .04H2.25A2.25 2.25 0 0 0 0 2.29v6.75a2.22 2.22 0 0 0 .66 1.59c.423.42.994.657 1.59.66H3a.75.75 0 1 0 0-1.5Z"/></svg>';

  }

  mount() {
    if(window.isSecureContext){
      this.button.classList.add("c-color__button--visible");
      this.button.addEventListener('click', this.copy);
    }else {

    }
  }

  copy() {
    this.svgDefult = this.buttonIcon;
    navigator.clipboard.writeText(this.hexaValue);
    this.buttonLabel.setAttribute('hidden', '');
    this.buttonCopied.removeAttribute('hidden');
    this.buttonIcon.innerHTML = this.svgColler;
    setTimeout(() => {
      this.buttonCopied.setAttribute('hidden', '');
      this.buttonLabel.removeAttribute('hidden');
      this.buttonIcon.innerHTML = this.svgDefault
    }, "5000")
  }

  unmount() {

  }
}
