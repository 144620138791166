import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Summary {
  constructor(element) {
    this.element = element;
    this.summary = this.element.querySelector(".js-transcript__summary");
    this.summaryLabel = this.element.querySelector(".c-transcription__link--control");

    this.toggle = this.toggle.bind(this);
  }

  mount() {
    this.element.setAttribute("id", "1");
    this.summary.setAttribute("aria-controls", "1");
    this.summary.setAttribute("aria-expanded", "false");
    this.summary.addEventListener('click', this.toggle);
  }

  toggle() {
    if (this.element.hasAttribute("open")) {
      this.summary.setAttribute("aria-expanded","false");
      this.summaryLabel.innerText ="Afficher la transcription";
    } else {
      this.summary.setAttribute("aria-expanded","true");
      this.summaryLabel.innerText ="Masquer la transcription";
    }
  }

  unmount() {

  }
}
