import 'mdn-polyfills/NodeList.prototype.forEach';
import Color from './Color';

document.addEventListener('DOMContentLoaded', () => {
  const colors = document.querySelectorAll('.js-color');
  if (!colors.length) {
    return;
  }

  colors.forEach((element) => {
    const color = new Color(element);
    color.mount(element);
  });
});
