import 'mdn-polyfills/NodeList.prototype.forEach';
import Summary from './Summary';

document.addEventListener('DOMContentLoaded', () => {
  const summarys = document.querySelectorAll('.js-transcription');
  if (!summarys.length) {
    return;
  }

  summarys.forEach((element) => {
    const summary = new Summary(element);
    summary.mount(element);
  });
});
